import Segment from 'utils/segment'
import wrapWithIntercom from 'components/common/utils/IntercomWrapper';

const onRouteUpdate = ({ location, prevLocation }) => {

  Segment.onRouteUpdate({ location, prevLocation })
};

const wrapRootElement = wrapWithIntercom;

export {
  onRouteUpdate,
  wrapRootElement
}
