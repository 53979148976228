import * as React from 'react';
import { IntercomProvider } from 'react-use-intercom';

export const INTERCOM_INITIALIZATION_DELAY = 6000
export const intercomBootProps = {
  open: false,
  targetPlatform: "charles",
  targetStack: "landing",
  patientWorkflow: "landing",
};

export const canBootIntercom = () => {
  const intercomId = process.env.GATSBY_INTERCOM_ID;
  return (typeof intercomId !== "undefined")
}

export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  if (canBootIntercom()){
    return (
      <IntercomProvider
        appId={process.env.GATSBY_INTERCOM_ID}
        initializeDelay={INTERCOM_INITIALIZATION_DELAY}
      >
        {element}
      </IntercomProvider>
    )
  }
  else {
    return element
  }
};

